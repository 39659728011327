import { Record, fromJS, Map } from "immutable"
import { createSelector } from "reselect"
import { selectRecordsByType } from "ducks/api"
import { selectInfiniteListState } from "ducks/infiniteList"
import { selectAlgoliaListState } from "ducks/algoliaList"
import { selectListState } from "ducks/list"
import { createAction } from "./utils"

const storeJS = require("utils/store.min.js")

export const types = {
  CHANGE_VOLUME: "soundstripe/audioPlayer/CHANGE_VOLUME",
  ENDED: "soundstripe/audioPlayer/ENDED",
  NEXT: "soundstripe/audioPlayer/NEXT",
  PLAY: "soundstripe/audioPlayer/PLAY",
  PREV: "soundstripe/audioPlayer/PREV",
  PROGRESS: "soundstripe/audioPlayer/PROGRESS",
  SEEK: "soundstripe/audioPlayer/SEEK",
  PAUSE: "soundstripe/audioPlayer/PAUSE",
  RESUME: "soundstripe/audioPlayer/RESUME",
  TOGGLE_PAUSE: "soundstripe/audioPlayer/TOGGLE_PAUSE",
  STOP: "soundstripe/audioPlayer/STOP",
  SHUFFLE: "soundstripe/audioPlayer/SHUFFLE",
}

export const actions = {
  changeVolume: createAction(types.CHANGE_VOLUME, "volume"),
  ended: createAction(types.ENDED, "data"),
  next: createAction(types.NEXT),
  play: createAction(types.PLAY, "data"),
  prev: createAction(types.PREV),
  progress: createAction(types.PROGRESS, "pos"),
  seek: createAction(types.SEEK, "seekPos"),
  pause: createAction(types.PAUSE),
  resume: createAction(types.RESUME),
  togglePause: createAction(types.TOGGLE_PAUSE),
  stop: createAction(types.STOP),
  shuffle: createAction(types.SHUFFLE, "data"),
}

export const AudioPlayerRecord = Record(
  {
    audioFileId: null,
    ended: false,
    playing: false,
    playerVisible: false,
    pos: 0,
    seekPos: null,
    radioIndex: null,
    radioPath: null,
    songId: null,
    songType: null,
    tunedGlobalId: null,
    parentSongId: null,
    childSongRank: 0,
    volume: storeJS.get("audioPlayerVolume") || 1,
    listStoreKey: null,
    playlistId: null,
    projectItemId: null,
    shuffled: false,
  },
  "AudioPlayer"
)

const initialState = new AudioPlayerRecord()

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_VOLUME:
      storeJS.set("audioPlayerVolume", action.volume)
      return state.set("volume", action.volume)

    case types.NEXT:
    case types.PREV:
      return state.set("ended", false)

    case types.PLAY:
      return state.merge(fromJS(action.data)).set("ended", false)

    case types.PROGRESS:
      return state.set("pos", action.pos)

    case types.SEEK:
      return state.set("seekPos", action.seekPos)

    case types.PAUSE:
      return state.set("playing", false)

    case types.RESUME:
      return state.set("playing", true)

    case types.TOGGLE_PAUSE:
      return state
        .set("playerVisible", true)
        .set("playing", !state.get("playing"))

    case types.ENDED:
      return state.merge(fromJS(action.data))

    case types.STOP:
      return state.set("playerVisible", false).set("playing", false)

    case types.SHUFFLE:
      return state.set("shuffled", !state.get("shuffled"))

    default:
      return state
  }
}

const audioPlayerState = (state) => state.getIn(["ducks", "audioPlayer"])
const selectSongId = (state) => state.getIn(["ducks", "audioPlayer", "songId"])
const selectSongType = (state) =>
  state.getIn(["ducks", "audioPlayer", "songType"])
const selectPlaylistId = (state) =>
  state.getIn(["ducks", "audioPlayer", "playlistId"])
const selectAudioFileId = (state) =>
  state.getIn(["ducks", "audioPlayer", "audioFileId"])
const selectListStoreKey = (state) =>
  state.getIn(["ducks", "audioPlayer", "listStoreKey"])
const selectRadioIndex = (state) =>
  state.getIn(["ducks", "audioPlayer", "radioIndex"])

export const selectAudioPlayer = () =>
  createSelector(audioPlayerState, (apState) => apState)

export const selectAudioPlayerSongType = () =>
  createSelector(audioPlayerState, (audioPlayer) => audioPlayer.songType)

export const selectSongAudioPlayerIsPlaying = () =>
  createSelector(audioPlayerState, (audioPlayer) => audioPlayer.playing)

export const selectPlayerVisible = () =>
  createSelector(audioPlayerState, (audioPlayer) => audioPlayer.playerVisible)

export const selectAudioPlayerShuffled = () =>
  createSelector(
    audioPlayerState,
    (audioPlayer) =>
      audioPlayer.shuffled &&
      audioPlayer.listStoreKey?.includes("playlist_songs")
  )

export const selectAudioPlayerSong = () =>
  createSelector(
    [
      selectSongId,
      selectSongType,
      selectRecordsByType("songs"),
      selectRecordsByType("marketplace_songs"),
    ],
    (songId, songType, recordsState, marketplaceRecordsState) => {
      const records =
        songType === "songs" ? recordsState : marketplaceRecordsState

      return records ? records.get(songId) : null
    }
  )

export const selectAudioPlayerPlaylist = () =>
  createSelector(
    [
      selectPlaylistId,
      selectSongType,
      selectRecordsByType("playlists"),
      selectRecordsByType("marketplace_playlists"),
    ],
    (playlistId, songType, recordsState, marketplaceRecordsState) => {
      const records =
        songType === "songs" ? recordsState : marketplaceRecordsState

      return records ? records.get(playlistId) : null
    }
  )

export const selectAudioPlayerAudioFile = () =>
  createSelector(
    [
      selectAudioFileId,
      selectSongType,
      selectRecordsByType("audio_files"),
      selectRecordsByType("marketplace_audio_files"),
    ],
    (audioFileId, songType, recordsState, marketplaceRecordsState) => {
      const records =
        songType === "songs" ? recordsState : marketplaceRecordsState

      return records ? records.get(audioFileId) : null
    }
  )

export const selectAudioPlayerSimilarParent = () =>
  createSelector(selectAlgoliaListState, (algoliaState) =>
    algoliaState.get("similar_song_parent")?.data.get(0)
  )

// If there is a storeKey name clash the list will be set to the InfiniteList.
export const selectAudioPlayerList = () =>
  createSelector(
    [
      selectListStoreKey,
      selectInfiniteListState,
      selectAlgoliaListState,
      selectListState,
    ],
    (listStoreKey, infListState, algoliaState, listState) => {
      const storeKey =
        algoliaState.get(listStoreKey) ||
        infListState.get(listStoreKey) ||
        listState.get(listStoreKey)
      return storeKey
    }
  )

export const selectAudioPlayerCursor = () =>
  createSelector(
    [
      selectAudioPlayerSimilarParent(),
      selectAudioPlayerSong(),
      selectAudioPlayerList(),
      selectRadioIndex,
      selectAudioPlayerShuffled(),
    ],
    (similarParent, audioPlayerSong, list, playerRadioIndex, shuffled) => {
      if (!list || playerRadioIndex == null) return null

      let currentAudio = null
      let nextAudio = null
      let prevAudio = null

      const listToPlay = shuffled ? list?.shuffledData : list?.data
      const radioIndex = listToPlay.findIndex(
        (item) => item.id === audioPlayerSong.get("id")
      )

      prevAudio =
        radioIndex > 0 ? listToPlay.get(radioIndex - 1) : similarParent

      if (similarParent?.id === audioPlayerSong.get("id")) {
        currentAudio = {
          id: audioPlayerSong.get("id"),
          type: audioPlayerSong.get("type"),
        }
        nextAudio = list.data.get(0)
      } else {
        currentAudio = listToPlay.get(radioIndex)
        nextAudio = listToPlay.get(radioIndex + 1)
      }

      return new Map({
        prev: prevAudio,
        current: currentAudio,
        next: nextAudio,
      })
    }
  )
