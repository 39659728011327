import { LOCATION_CHANGE } from "react-router-redux"
import { buffers } from "redux-saga"
import { call, put, take, race, flush, actionChannel } from "redux-saga/effects"
import {
  get,
  prependApiUrl,
  updatePageAndFilterQueryString,
} from "utils/request"
import { types, actions as infiniteListActions } from "ducks/infiniteList"
import { actions as apiActions } from "ducks/api"
import { actions as searchFilterActions } from "ducks/searchFilter"
import { actions as uiActions } from "ducks/ui"

export function* createList(action) {
  try {
    const endpoint = updatePageAndFilterQueryString(action.requestPath, {
      offset: 0,
      limit: action.limit,
      query: null,
    })
    const requestURL = prependApiUrl(endpoint)
    const response = yield call(get, requestURL)
    const tagIdsResponse = response.meta && response.meta.unique_tags
    if (tagIdsResponse) {
      yield put(searchFilterActions.storeTagIds(tagIdsResponse))
    }
    const ids = response.data.map((item) => {
      return { id: item.id, type: item.type }
    })
    yield put(apiActions.readSuccess(response))
    yield put(infiniteListActions.store(action.storeKey, ids, response.links))
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(infiniteListActions.createFailed(action.storeKey))
  }
}

export function* loadMore(action) {
  try {
    const response = yield call(get, action.nextLink)
    const ids = response.data.map((item) => {
      return { id: item.id, type: item.type }
    })
    yield put(apiActions.readSuccess(response))
    yield put(infiniteListActions.store(action.storeKey, ids, response.links))
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(apiActions.requestFailed(err))
  }
}

export function* watchCreateAndLoadMore() {
  // 1. Create a channel to watch for LOAD_MORE actions and queue them
  const requestChan = yield actionChannel(
    [types.CREATE, types.LOAD_MORE],
    buffers.expanding(10)
  )

  const SAGAS = {
    [types.CREATE]: createList,
    [types.LOAD_MORE]: loadMore,
  }

  while (true) {
    // 2. Pop request from the channel
    const action = yield take(requestChan)
    // 3. Run a race to see if this task finishes or if it's canceled before then.
    const { cancel } = yield race({
      task: call(SAGAS[action.type], action),
      cancel: take([types.CANCEL_LOAD_MORE, LOCATION_CHANGE]),
    })

    // If the task was canceled, flush the queue and do nothing with the remaining
    // actions.
    if (cancel !== undefined) {
      yield flush(requestChan)
      // Don't need these actions, do nothing.
    }
  }
}

export default [watchCreateAndLoadMore]
