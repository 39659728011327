import { Record, fromJS, OrderedMap, List } from "immutable"
import { createSelector } from "reselect"
import { selectRecordsByType } from "ducks/api"
import { createAction } from "./utils"

export const types = {
  CREATE: "soundstripe/list/CREATE",
  LOAD: "soundstripe/list/LOAD",
  LOAD_FAILURE: "soundstripe/list/LOAD_FAILURE",
  LOAD_SUCCESS: "soundstripe/list/LOAD_SUCCESS",
  DELETE: "soundstripe/list/DELETE",
}

export const actions = {
  create: createAction(types.CREATE, "storeKey", "requestPath", "limit"),
  load: createAction(types.LOAD, "storeKey"),
  loadFailed: createAction(types.LOAD_FAILURE, "storeKey"),
  loadSuccess: createAction(types.LOAD_SUCCESS, "storeKey", "data"),
  delete: createAction(types.DELETE, "storeKey"),
}

export const ListRecord = Record(
  {
    storeKey: null,
    requestPath: null,
    limit: 0,
    data: new List(),
    loading: true,
    failed: false,
  },
  "List"
)

const initialState = fromJS({})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE:
      return state.set(
        action.storeKey,
        new ListRecord({
          storeKey: action.storeKey,
          requestPath: action.requestPath,
          limit: action.limit,
        })
      )
    case types.LOAD:
      return state.setIn([action.storeKey, "loading"], true)
    case types.LOAD_FAILURE:
      return state
        .setIn([action.storeKey, "failed"], true)
        .setIn([action.storeKey, "loading"], false)
    case types.LOAD_SUCCESS:
      const currentList = state.get(action.storeKey)
      const newList = currentList.merge({
        data: List(action.data),
        loading: false,
      })
      return state.set(action.storeKey, newList)
    case types.DELETE:
      return state.remove(action.storeKey)
    default:
      return state
  }
}

export const selectListState = (state) => state.getIn(["ducks", "list"])

export const selectListByStoreKey = (storeKey) =>
  createSelector(selectListState, (listState) => {
    return listState.get(storeKey)
  })

export const selectRecordsFromList = (storeKey, typeOfRecord) =>
  createSelector(
    selectListState,
    selectRecordsByType(typeOfRecord),
    (listState, recordTypeState) => {
      const infList = listState.get(storeKey)
      const records = recordTypeState
      const data = infList && infList.data
      if (!data || !records) return {}

      const orderedMap = new OrderedMap({}).withMutations((oMap) => {
        data.forEach((k) => {
          oMap.set(k.id, records.get(k.id))
        })
      })
      return orderedMap
    }
  )
