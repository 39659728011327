import { LOCATION_CHANGE, push } from "react-router-redux"
import { buffers } from "redux-saga"
import {
  call,
  put,
  take,
  race,
  flush,
  actionChannel,
  select,
} from "redux-saga/effects"
import { selectCurrentUserRecord } from "ducks/currentUser"
import { types, actions as algoliaListActions } from "ducks/algoliaList"
import { actions as apiActions } from "ducks/api"
import { actions as uiActions } from "ducks/ui"
import { actions as trackingActions } from "ducks/tracking"
import trackMixpanelMusicSearch from "pages/SongsPage/MixpanelTracking"
import trackMixpanelVideoSearch from "pages/VideosPage/MixpanelTracking"
import trackMixpanelSoundEffectsSearch from "pages/SoundEffectsPage/trackMixpanelSoundEffectSearch"
import trackMixpanelMarketplaceSearch from "pages/MarketplacePage/SearchBar/trackMixpanelMarketplaceSearch"
import { searchClient } from "utils/algolia"
import {
  VISITOR_SEARCH_LOCATIONS_TRIGGER_COOKIE,
  actions as cookieActions,
} from "middlewares/cookies"
import { actions as modalActions } from "ducks/modal"

function checkForSearchQueryByType(type, params) {
  if (params.query && params.query.length >= 3) {
    return true
  }
  if (type.includes("videos")) {
    const hasFacetFilter = params?.facetFilters?.some((filter) => {
      if (Array.isArray(filter) || typeof filter === "string") {
        return filter.length > 0
      }
      if (typeof filter === "object") {
        return Object.keys(filter).length > 0
      }
      return !!filter
    })

    const hasNumericFilter = !!params?.numericFilters?.length

    return hasFacetFilter || hasNumericFilter
  }

  if (type.includes("songs")) {
    return (
      (params?.facetFilters?.length && params?.facetFilters?.length > 1) ||
      (params?.numericFilters?.length && params?.numericFilters?.length > 1)
    )
  }

  if (type.includes("soundEffects")) {
    return !!params?.facetFilters?.length
  }

  return false
}

function* incrementLoggedOutSearchCount(location) {
  // Store as array so reloads don't increment the count
  const searchArrayFromCookies = yield put(
    cookieActions.getCookie(VISITOR_SEARCH_LOCATIONS_TRIGGER_COOKIE)
  ) || ""

  let removed = false

  const getExpirationAndValues = () => {
    if (!searchArrayFromCookies) {
      return {
        expiration: null,
        values: [],
      }
    }
    const [expiration, values] = searchArrayFromCookies.split("|")
    return {
      expiration,
      values: JSON.parse(values),
    }
  }

  const { expiration, values } = getExpirationAndValues()
  const now = Date.now()
  if (expiration && now > expiration) {
    cookieActions.removeCookie(VISITOR_SEARCH_LOCATIONS_TRIGGER_COOKIE)
    removed = true
  }
  const oneDayFromNow = now + 86400000

  // Solves the issue with reloading a page
  if (removed || values.length === 0) {
    yield put(
      cookieActions.setCookie(
        VISITOR_SEARCH_LOCATIONS_TRIGGER_COOKIE,
        `${oneDayFromNow}|${JSON.stringify([location])}`
      )
    )
    return
  }

  if (values.includes(location)) {
    return
  }

  const newSearchArray = [...values, location]

  yield put(
    cookieActions.setCookie(
      VISITOR_SEARCH_LOCATIONS_TRIGGER_COOKIE,
      `${expiration}| ${JSON.stringify(newSearchArray)}`
    )
  )

  if (newSearchArray.length === 3) {
    const marketPage = window.location.pathname.includes("market")
    yield put(
      trackingActions.trackMixpanel("Viewed Modal", {
        context: "Search Triggered",
        marketplace: marketPage,
      })
    )
    yield put(
      modalActions.open("SignUpModal", {
        action: marketPage ? "marketplace_search-limit" : "search-limit",
      })
    )
  }
}

export function* createList(action) {
  try {
    const { indexName, query, ...params } = action.params
    const userRecord = yield select(selectCurrentUserRecord())
    const algoliaRes = yield call(searchClient.search, [
      {
        clickAnalytics: true,
        userToken: userRecord.get("id"),
        indexName,
        query,
        params,
        ruleContexts: action.ruleContexts,
      },
    ])

    if (action.search && (!!action.params.query || !!action.params.filters)) {
      if (action.storeKey === "songsList" || action.storeKey === "songs") {
        yield put(
          trackingActions.trackMixpanel(
            "Searched Songs",
            trackMixpanelMusicSearch(action.search)
          )
        )
      }
      if (action.storeKey === "videosList") {
        yield put(
          trackingActions.trackMixpanel(
            "Searched Videos",
            trackMixpanelVideoSearch(action.search)
          )
        )
      }
      if (action.storeKey === "soundEffectsList") {
        yield put(
          trackingActions.trackMixpanel(
            "Searched Sound Effects",
            trackMixpanelSoundEffectsSearch(action.search.query)
          )
        )
      }
      if (action.storeKey === "marketplace_songs") {
        yield put(
          trackingActions.trackMixpanel(
            "Searched Marketplace Songs",
            trackMixpanelMarketplaceSearch(action.search)
          )
        )
      }
    }
    if (!action.hitsMapFn) {
      console.error("Algolia Create List Saga Error: You must supply hitMapFn")
    }
    const result = algoliaRes.results ? algoliaRes.results[0] : {}
    const { data, included } = action.hitsMapFn(result.hits)
    yield put(
      apiActions.readSuccess({
        data,
        included,
      })
    )
    yield put(
      algoliaListActions.store(
        action.storeKey,
        data.map((file) => {
          return { id: file.id, type: file.type }
        }),
        result
      )
    )
    if (!userRecord?.id) {
      const listType = action.storeKey
      const hasSearchQuery = checkForSearchQueryByType(listType, action.params)
      if (hasSearchQuery) {
        yield incrementLoggedOutSearchCount(action.search.location.search)
      }
    }
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(algoliaListActions.createFailed(action.storeKey))
  }
}

export function* loadMore(action) {
  try {
    const { indexName, query, ...params } = action.params
    const userRecord = yield select(selectCurrentUserRecord())
    const algoliaRes = yield call(searchClient.search, [
      {
        clickAnalytics: true,
        userToken: userRecord.get("id"),
        indexName,
        query,
        params: {
          ...params,
          page: action.page,
          ruleContexts: action.ruleContexts,
        },
      },
    ])
    if (!action.hitsMapFn) {
      console.error("Algolia Create List Saga Error: You must supply hitMapFn")
    }
    const result = algoliaRes.results ? algoliaRes.results[0] : {}
    const { data, included } = action.hitsMapFn(result.hits)
    yield put(
      apiActions.readSuccess({
        data,
        included,
      })
    )
    yield put(
      algoliaListActions.store(
        action.storeKey,
        data.map((file) => {
          return { id: file.id, type: file.type }
        }),
        result
      )
    )
  } catch (err) {
    yield put(uiActions.setError(err))
    yield put(algoliaListActions.createFailed(action.storeKey))
  }
}

export function* watchCreateAndLoadMore() {
  // 1. Create a channel to watch for LOAD_MORE actions and queue them
  const requestChan = yield actionChannel(
    [types.CREATE, types.LOAD_MORE],
    buffers.expanding(10)
  )

  const SAGAS = {
    [types.CREATE]: createList,
    [types.LOAD_MORE]: loadMore,
  }

  while (true) {
    // 2. Pop request from the channel
    const action = yield take(requestChan)
    // 3. Run a race to see if this task finishes or if it's canceled before then.
    const { cancel } = yield race({
      task: call(SAGAS[action.type], action),
      cancel: take([
        (a) =>
          a.type === types.CANCEL_LOAD_MORE && a.storeKey === action.storeKey,
        LOCATION_CHANGE,
      ]),
    })

    // If the task was canceled, flush the queue and do nothing with the remaining
    // actions.
    if (cancel !== undefined) {
      let actions = yield flush(requestChan)

      // Flush actions for this store key
      if (actions) {
        actions = actions.filter(({ storeKey }) => storeKey !== action.storeKey)

        for (let i = 0; i < actions.length; ++i) {
          yield push(requestChan, actions[i])
        }
      }
    }
  }
}

export default [watchCreateAndLoadMore]
